import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/navbar.css";
import Logo from "../../src/assets/img/usl_logo.jpeg";

export default function Navbar() {
  return (
    <header
      id="header"
      className="d-flex align-items-center z-index=100 position=fixed width=100% "
    >
      <div className="container d-flex align-items-center">
        <div className="logo">
          <Link to="/" className="logo-link">
            <img src={Logo} alt="Agreez Logo" className="logo-img" />
          </Link>
        </div>

        <a href="index.html" className="logo me-auto">
          <img
            src="../../src/assets/img/usl_logo.jpeg"
            alt=""
            className="img-fluid"
          />
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <Link to="/" className="nav-link scrollto">
                Home
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/#" className="nav-link scrollto">
                Product<span className="highlight">'s</span>{" "}
                <i className="bi bi-chevron-down"></i>
              </Link>

              <ul>
                <li>
                  <Link to="/Insecticide" className="nav-link scrollto">
                    INSECTICIDE
                  </Link>
                </li>

                <li>
                  <Link to="/Herbicide" className="nav-link scrollto">
                    HERBICIDE
                  </Link>
                </li>
                <li>
                  <Link to="/Fungicide" className="nav-link scrollto">
                    FUNGICIDE
                  </Link>
                </li>
                <li>
                  <Link to="/PGR" className="nav-link scrollto">
                    PGR
                  </Link>
                </li>
                {/* <li>
                  <Link to="/Other" className="nav-link scrollto">
                    Others
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="dropdown">
              <Link to="/#" className="nav-link scrollto">
                This is&nbsp;<span className="highlight">USLCrop</span>{" "}
                <i className="bi bi-chevron-down"></i>
              </Link>
              <ul>
                {/* <h6>Leadership</h6> */}
                <li>
                  <Link to="/FounderChairman" className="nav-link scrollto">
                    Founder Chairman
                  </Link>
                </li>
                <li>
                  <Link to="/QC" className="nav-link scrollto">
                    Quality Control Policy
                  </Link>
                </li>
                <li>
                  <Link to="/Vision" className="nav-link scrollto">
                    Our Mission & Strategy
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/Contact" className="nav-link scrollto">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/About" className="nav-link scrollto">
                About
              </Link>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}
