import React from "react";
import "../assets/css/footer.css";

export default function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <h3>
          USL&nbsp;<span className="highligh">Crop</span>&nbsp;Science
        </h3>
        <div className="container text-center">
          <a href="https://www.facebook.com/profile.php?id=100094450271928&mibextid=LQQJ4d">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://www.agreezagro.in">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="https://www.agreezagro.in">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://www.agreezagro.in">
            <i className="fa fa-google-plus"></i>
          </a>
          <a href="https://www.agreezagro.in">
            <i className="fa fa-skype"></i>
          </a>
        </div>
        <br />
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>USL Crop</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by:
          <a href="https://www.uslcropsciencecrop.com" className="copyright">
            USL
            <strong>
              <span className="highligh">Crop</span>
            </strong>
          </a>
        </div>
      </div>
    </footer>
  );
}
