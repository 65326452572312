import React from "react";
import { Card } from "react-bootstrap";
import "../../assets/css/product.css";

export default function CardProduct({
  imageSrc,
  title,
  subtitle,
  description,
  top,
}) {
  return (
    <Card className="CardItem  img-fluid text-center my-2 d-flex justify-content-between  ">
      <Card.Img className="CardItem-img   " variant="top" src={imageSrc} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}




