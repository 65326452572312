import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../assets/css/style.css";

import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const data = [
  {
    image: require("../assets/img/slide/s1.jpg"),
    // caption: "Caption",
    // description: "Description Here",
  },
  {
    image: require("../assets/img/slide/s2.jpg"),
    // caption: "Caption",
    // description: "Description Here",
  },
  {
    image: require("../assets/img/slide/s3.jpg"),
    // caption: "Caption",
    // description: "Description Here",
  },
  {
    image: require("../assets/img/slide/s4.jpg"),
    // caption: "Caption",
    // description: "Description Here",
  },
];

export default function Slider() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {data.map((slide, i) => (
          <Carousel.Item key={i}>
            <img
              className="carousel-item active "
              src={slide.image}
              alt="slider"
              // className="d-block w-vw"
              // style={{ height: "auto", width: "100%", maxHeight: "800px" }}
              // src={slide.image}
              // alt="First slide"
            />
            <Carousel.Caption>
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}
