import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

// import "../../assets/css/product.css";
import CardProduct from "./CardProduct";
// import Product from "../Product";
import DATA from "../Json/data.json";
import { Container, Row, Col } from "react-bootstrap";

export default function AllProducts() {
  return (
    <>
      <section id="" className="">
        <div className="">
          <div className="section-title">
            <h2 className="text-center my-4">All-Products</h2>
          </div>
          <Container fluid>
            <Row className=" d-flex w-100 p-3 h-75 d-inline-block">
              {DATA.All.map((item, index) => (
                <Col xs={12} sm={6} md={4} lg={2} key={index} className="mb-0">
                  <CardProduct
                    imageSrc={item.image}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.descri}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
