import React from "react";
import { Link } from "react-router-dom";
import DATA from "../Components/Json/sample.json";
import CardProduct from "././Product/CardProduct";

import { Container, Row, Col } from "react-bootstrap";

export default function Product() {
  return (
    <>
      <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <h2>Portfolio</h2>
          </div>
          <Container fluid>
            <Row className="d-flex justify-content-center p-5">
              {DATA.data.map((item, index) => (
                <Col xs={12} sm={6} md={6} lg={6} key={index} className="mb-0">
                  {/* Wrap CardProduct with Link component */}
                  <Link to={item.link}>
                    <CardProduct
                      imageSrc={item.image}
                      title={item.title}
                      subtitle={item.subtitle}
                      description={item.descri}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
