import React from "react";
import "../assets/css/style.css";

import About from "./About";
// import Feature from "./Feature";
// import WhyUS from "./WhyUS";
import Slider from "./Slider";
import Contact from "./Contact";
import Product from "./Product";
// import FounderChairman from "./FounderChairman";

export default function Home() {
  return (
    <>
      <div
        className="carousel-inner"
        role="listbox"
        style={{ height: "600px", objectFit: "cover" }}
      >
        <Slider />
      </div>
      <Product />
      <About />
      {/* <FounderChairman /> */}
      <Contact />
      {/* <Feature /> */}
      {/* <WhyUS /> */}
    </>
  );
}
