import React from "react";
// import about from "../assets/img/about.jpg";

export default function FounderChairman() {
  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h3>Coming Soon</h3>
            {/* <h2>Founder Chairman</h2> */}
            {/* <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> --> */}
            {/* </div>
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-2">
              <img src={about} className="img-fluid" alt="test" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <ul>
                <li>
                  <h4>
                    <b> MR. KAUSHLESH SHARMA</b>
                    <br />
                    {" Director"}
                  </h4>
                </li>
                <p></p>
              </ul>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
