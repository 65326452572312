import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../assets/css/product.css";
import CardProduct from "./CardProduct";
import DATA from "../Json/data.json";
import { Container, Row, Col } from "react-bootstrap";

export default function Insecticide() {
  return (
    <>
      <section id="product" className="product">
        <div className="">
          <div className="section-title">
            <h2 className="text-center my-4">Insecticide</h2>
          </div>
          <Container fluid>
            <Row className="d-flex justify-content-center p-5">
              {DATA.Insecticide.map((item, index) => (
                <Col xs={12} sm={6} md={4} lg={2} key={index} className="mb-0">
                  <CardProduct
                    imageSrc={item.image}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.descri}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
